<template>
	<div
		class="full-height flex-column bg-gray-light"
	>
		<div
			class="pa-10 full-height pb-30 overflow-y-auto "
		>
			<div class="">

				<h6>주문정보</h6>
				<div class="pa-10 box-shadow bg-white">
					<div
						class="justify-space-between font-weight-bold under-line-dashed pb-10"
					>
						<span>주문번호</span>
						<span>{{ item.order_number }}</span>
					</div>

					<div
						class="mt-10 justify-space-between"
					>
						<span class="font-weight-bold">주문일시</span>
						<span>{{ item.wDate }}</span>
					</div>
				</div>
			</div>

			<div class="mt-10">
				<h6>주문자 정보</h6>
				<div
					class="pa-10 bg-white box-shadow"
				>
					<div class=" justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">이름</span>
						<span>{{ item.member_name }}</span>
					</div>
					<div class="mt-5  justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">연락처</span>
						<span>{{ item.member_tell }}</span>
					</div>
				</div>
			</div>

			<div class="mt-10">
				<h6>수신자 정보</h6>
				<div
					class="pa-10 bg-white box-shadow"
				>
					<div class=" justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">이름</span>
						<span>{{ item.d_name }}</span>
					</div>
					<div class="mt-5  justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">연락처</span>
						<span>{{ item.d_tell }}</span>
					</div>
				</div>
			</div>

			<div
				v-if="false"
				class="mt-10"
			>

				<h6>배송 구분</h6>

				<div
					class="bg-white pa-10 box-shadow font-weight-bold"
				>
					{{ item.delivery_type == 'D002002' ? '방문 수령' : '택배 배송'}}
				</div>
			</div>

			<div class="mt-10 flex-row justify-space-between items-center">
				<h6>상품 정보</h6>
				<template
					v-if="false"
				>
					<button
						v-if="is_purchase"
						class="btn-inline btn-primary"
						@click="onPurchase"
					>매입 처리</button>
					<button
						v-else-if="!is_purchase && item.is_purchase == 1"
						class="btn-inline btn-primary"
						@click="copy"
					><v-icon small class="color-white">mdi-content-copy</v-icon> 매입 코드</button>
				</template>
			</div>

			<template
				v-if="item.supply_list.length > 0"
			>
				<ul class="">
					<li
						v-for="(supply, item_index) in item_list"
						:key="item_index"
						class="under-line mb-10  bg-white box-shadow"
					>
						<ul>
							<li
								v-for="(odt, product_index) in supply.odt_list"
								:key="'product_' + product_index"
								class="pa-10 under-line full-height "
							>
								<div
									class="justify-space-between under-line mb-10 pb-10"
								>
									<div
										class="flex-1 flex-column justify-center "
										:class="'color-' + odt.order_status_color"
									>{{ odt.order_status_name }}</div>

									<button
										v-if="odt.shipping_num"
										class="box prl-10 color-blue"
										@click="toShipping(odt)"
									>배송조회</button>
								</div>
								<div class="justify-space-between under-line-dashed pb-10 ">

									<div class="flex-1 full-height pdt-img justify-center flex-column mr-10">
										<img
											v-if="odt.pdt_img"
											:src="odt.pdt_img"
										/>
										<v-icon
											v-else
										>mdi mdi-image</v-icon>
									</div>
									<div class="flex-3">
										<div class="under-line-dashed">
											{{ odt.pdt_name }}
										</div>

										<div class="under-line-dashed  color-gray  ptb-5 mb-5">
											<span>선택옵션: </span>
											<span>{{ odt.op_name}}</span>
										</div>
										<div
											class="justify-space-between"
										>
											<span
												class="flex-2 font-weight-bold"
											>{{ odt.pdt_price | makeComma }} 원</span>

											<span>수량: {{ odt.op_cnt }} 개</span>
										</div>
									</div>
								</div>
								<div class="justify-space-between">

									<div class="flex-3 mt-10 size-px-10 text-right">
										<span
											v-if="false && odt.is_cancel && (!item.is_purchase || item.is_purchase == '0') && seller_info.sales_type == '1'"
											class=" prl-10 bg-red"
											@click="isOdtUpdate(odt,'step21')"
										>주문 취소</span>
										<span
											v-if="odt.is_confirm"
											class="box prl-10 bg-green"
											@click="toOdtConfirm(odt)"
										>구매 확정</span>
										<span
											v-if="false && odt.is_return && (!item.is_purchase || item.is_purchase == '0')"
											class="box prl-10 bg-orange mr-5"
											@click="toOdtReturn(odt)"
										>교환 / 반품</span>
									</div>
								</div>
								<div
									v-if="odt.reason"
									class="top-line-dashed mt-10 pt-10"
								>
									<div>{{ odt.reason_name}}</div>
									<div>{{ odt.reason_text}}</div>
								</div>
							</li>
						</ul>
						<div
							class="pa-10 justify-space-between under-line-dashed"
						>
							<span class="font-weight-bold">상품 합계</span>
							<span class="color-blue font-weight-bold">{{ supply.total_price | makeComma }} 원</span>
						</div>
						<div
							v-if="false"
							class="pa-10 justify-space-between "
						>
							<span class="font-weight-bold">배송비 </span>
							<span class="font-weight-bold">{{ supply.delivery_price | makeComma }} 원</span>
						</div>
						<div
							v-if="supply.island_delivery > 0"
							class="mb-10 prl-10 justify-space-between "
						>
							<span class="font-weight-bold">도서산간 추가 배송비</span>
							<span class="font-weight-bold">{{ supply.island_delivery | makeComma }} 원</span>
						</div>
					</li>
				</ul>
			</template>

			<div
				v-if="is_m"
				class="mt-30"
			>
				<div class="justify-space-between items-center">
					<h6 class="color-primary">상품권 정보</h6>

					<div>
						<button
							v-if="item.o_status == 2 && is_made_coupon"
							class="btn-primary pa-5-10 mr-10"
							@click="onMileage"
						>마일리지 전환</button>
					<button
						v-if="item.o_status == 2"
						class="btn-primary pa-5-10"
						@click="copy2"
					>복사</button>
					</div>
				</div>

				<div
					v-if="item.o_status == 2"
				>
				<table
					class="table  mt-10 box-shadow"
				>
					<colgroup>
						<col width="auto" />
						<col width="auto" />
						<col width="70px" />
					</colgroup>
					<thead>
					<tr>
						<th>PIN</th>
						<th>비밀번호</th>
						<th>상세정보</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="item in items_pin"
						:key="item.uid"
					>
						<td>{{ item.tranStatus == '0' ? '대기' : item.tranStatus == 'fail' ? '오류' : item.orgTranSeq ? '취소' : item.appNo }}</td>
						<td>{{ item.orgTranSeq ? '' : item.issCd }}</td>
						<td>
							<v-icon
								@click="getDetail(item)"
								class="color-icon"
							>mdi mdi-arrow-right-bold-box-outline</v-icon>
						</td>
					</tr>
					</tbody>
				</table>

				<Pagination
					:options="item_search"

					@click="getSearch"
				></Pagination>
				</div>
				<div
					v-else
					class="bg-white pa-10"
				>
					PIN 목록은 결제 완료 후 조회 가능합니다.
				</div>
			</div>

			<h6 class="mt-10">결제 정보</h6>
			<div
				class="pa-10 bg-white box-shadow font-weight-bold"
			>
				<div class=" mt-5  justify-space-between under-line-dashed">
					<span class="">결제 상태</span>
					<span :class="'color-' + item.o_status_color ">{{ order_status }}</span>
				</div>
				<div class=" justify-space-between under-line-dashed">
					<span class="font-weight-bold">결제금액</span>
					<span><span class="color-blue font-weight-bold">{{ item.order_price | makeComma }}</span> 원</span>
				</div>
				<div class=" mt-5  justify-space-between under-line-dashed">
					<span class="font-weight-bold">결제</span>
					<span>{{ pay_div }}</span>
				</div>
				<div class=" mt-5 text-right ">
					{{ item.issNm }}
					{{ item.cardNum }}
				</div>
			</div>

			<div
				v-if="item.o_status == 2"
				class="mt-30 mb-30"
			>
				<button
					class="btn btn-primary"
					@click="onReceipt"
				>영수증</button>
			</div>
		</div>


		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			@close="is_modal = !is_modal"
		>
			<div
				slot="modal-bottom"
				class="justify-space-between"
			>
				<button
					class="btn bg-red"
					@click="toOdtStatus"
				>확인</button>
				<button
					class="btn btn-default"
					@click="clearItem"
				>닫기</button>
			</div>
		</Modal>

		<PopupConfirm
			v-if="is_on_purchase"

			@cancel="is_on_purchase = false"
			@click="postPurchaseRequest"
		>

			<template
				v-slot:title
			>매입 요청</template>
			<template
				v-slot:main-txt
			>매입 요청 하시겠습니까?</template>
		</PopupConfirm>

		<PopupLayer
			v-if="is_on_receipt"
		>
			<template
				v-slot:body
			>
				<div class="pa-40 bg-white full-height flex-column radius-20">
					<div class="bg-popup-title pa-10-20 flex-row justify-space-between items-center">
						<h4>영수증</h4>
						<v-icon
							@click="is_on_receipt = false"
						>mdi-close-circle</v-icon>
					</div>

					<hr class="under-line ">

					<div class="overflow-y-auto">

						<div class="justify-space-between-in pa-10">
							<div>
								<span>카드사/승인번호</span>
								{{ item.issNm }} / {{ item.appNo }}
							</div>
							<div
								v-if="false"
							>
								<span>카드번호/유효기간</span>
							</div>
							<div>
								<span>할부</span>
								{{ installment }}
							</div>
							<div>
								<span>결제일자</span>
								{{ item.mDate }}
							</div>
							<div
								v-if="false"
							>
								<span>상품명</span>
							</div>
							<div>
								<span>상품 주문번호</span>
								{{ item.order_number}}
							</div>
						</div>

						<div class="justify-space-between-in pa-10 top-line">
							<h6>판매자 정보</h6>
							<div>
								<span>판매자 상호</span>
								{{ seller_info.business_name}}
							</div>
							<div>
								<span>대표자명</span>
								{{ seller_info.shop_ceo}}
							</div>
							<div>
								<span>사업자등록번호</span>
								{{ seller_info.front}}
							</div>
							<div>
								<span>전화번호</span>
								{{ seller_info.shop_tell }}
							</div>
							<div>
								<span>사업장주소</span>
								{{ seller_info.shop_address}}
								{{ seller_info.shop_address_detail}}
							</div>
						</div>


						<div class="justify-space-between-in pa-10 top-line">
							<h6>가맹점 정보</h6>
							<div>
								<span>가맹점 명</span>
								{{ item.pg_shop_name }}
							</div>
							<div>
								<span>대표자명</span>
								{{ item.pg_ceo }}
							</div>
							<div>
								<span>가맹점번호</span>
								{{ item.pg_tell}}
							</div>
							<div>
								<span>사업자등록번호</span>
								{{ item.pg_front }}
							</div>
						</div>
						<div class="text-right prl-10 mb-10">
							{{ item.pg_address }} <br/>
							{{ item.pg_address_detail }}
						</div>


						<div class="justify-space-between-in pa-10 top-line">
							<h6>금액</h6>
							<div>
								<span>승인금액</span>
								{{ item.totAmt | makeComma}}
							</div>
							<div>
								<span>공급가액</span>
								{{ item.totAmt | makeComma}}
							</div>
							<div>
								<span>부가세액</span>
								{{ item.totAmt - item.totAmt | makeComma}}
							</div>
							<div>
								<span>봉사료</span>
								{{ item.tt | makeComma}}
							</div>
						</div>
						<div class="justify-space-between top-line pa-10">
							<h6>합계</h6>
							{{ item.order_price | makeComma}}
						</div>
					</div>
				</div>

			</template>
		</PopupLayer>
		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@close="is_excel = false"
		></Excel>

		<PopupLayer
			v-if="is_on_detail"
			:width="'480px'"
		>
			<template
				v-slot:body
			>
				<div
					class="pa-20 flex-column overflow-y-auto bg-white"
				>
					<div class="justify-space-between">
						<h6>모바일 상품권 상세 정보</h6>
						<button
							@click="is_on_detail = false"
						><v-icon>mdi mdi-close-circle</v-icon></button>
					</div>
					<table class="table mt-10 td-left">
						<col width="120px" />
						<tbody>
						<tr>
							<th>발급일시</th>
							<td>{{ item_detail.appDt ? item_detail.appDt : item_detail.wDate}}</td>
						</tr>
						<tr>
							<th>TID</th>
							<td>{{ item_detail.tranSeq}}</td>
						</tr>
						<tr>
							<th>상품권</th>
							<td>{{ item_odt.op_name }} {{ item_detail.totAmt }}원권</td>
						</tr>
						<tr>
							<th>PIN</th>
							<td>{{ item_detail.appNo}}</td>
						</tr>
						<tr>
							<th>비밀번호</th>
							<td>{{ item_detail.issCd }}</td>
						</tr>
						<tr>
							<th>아이디 </th>
							<td>{{ item.member_id}}</td>
						</tr>
						<tr>
							<th>수신자 이름 </th>
							<td>{{ item.d_name}}</td>
						</tr>
						<tr>
							<th>수신 번호 </th>
							<td>{{ item.d_tell}}</td>
						</tr>
						<tr>
							<th>처리 상태</th>
							<td>{{ item_detail.resultStatus}}</td>
						</tr>
						<tr>
							<th>사용 상태</th>
							<td :class="{'color-red' : item_detail.orgTranSeq}">{{ item_detail.tranStatusName}}</td>
						</tr>
						<tr>
							<th>전환 상태</th>
							<td :class="{'color-red' : item_detail.posTerminalNo}">{{ item_detail.posTerminalNo}}</td>
						</tr>
						<tr
							v-if="item_detail.orgTranSeq"
						>
							<th>취소 TID</th>
							<td>{{ item_detail.orgTranSeq}}</td>
						</tr>
						<tr
							v-if="item_detail.orgTranSeq"
						>
							<th>취소 일시</th>
							<td>{{ item_detail.appTm}}</td>
						</tr>
						</tbody>
					</table>

					<div
						v-if="item_detail.tranSeq"
						class="mt-10 text-right"
					>
						<button
							v-if="!item_detail.orgTranSeq && !item_detail.pgTidNm"
							class="btn btn-danger"
							@click="postCancel"
						>취소 요청</button>
					</div>
					<div
						v-else
						class="mt-10 text-right"
					>
						<button
							v-if="item.tranStatus != '1000'"
							class="btn btn-danger"
							@click="postTry"
						>재발행 요청</button>
					</div>
				</div>
			</template>
		</PopupLayer>

		<PopupLayer
			v-if="is_on_mileage"
			:width="'480px'"
		>
			<template
				v-slot:body
			>
				<div
					class="pa-20 flex-column overflow-y-auto bg-white"
				>
					<div class="justify-space-between">
						<h6>마일리지 전환</h6>
						<button
							@click="is_on_mileage = false"
						><v-icon>mdi mdi-close-circle</v-icon></button>
					</div>
					<div style="max-height: 480px;" class="overflow-y-auto">
						<table
							class="table  mt-10 box-shadow"
						>
							<colgroup>
								<col width="auto" />
								<col width="auto" />
							</colgroup>
							<thead>
							<tr>
								<th>PIN</th>
								<th>상태</th>
							</tr>
							</thead>
							<tbody>
							<tr
								v-for="item in item.order_return"
								:key="item.uid"
							>
								<td>{{ item.tranStatus == '0' ? '대기' : item.tranStatus == 'fail' ? '오류' : item.orgTranSeq ? '취소' : item.appNo }}</td>
								<td>{{ item.mileage_status_name }}</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div
						class="mt-10 text-right "
					>
						<div
							v-if=user.member_memo
							class="input-box"
						>{{ item_mileage.member_id }}</div>
						<input
							v-else
							v-model="item_mileage.member_id"
							placeholder="메이드 쿠폰 아이디"
							class="input-box"
						/>
						<div
							v-if=user.member_memo
							class="input-box"
						>{{ item_mileage.member_pw }}</div>
						<input
							v-else
							v-model="item_mileage.member_pw"
							placeholder="비밀번호"
							class="input-box mt-10"
						/>

						<button
							v-if="is_mileage"
							class="btn-primary pa-5-10 mt-10"
							@click="postMileage"
						>마일리지 전환</button>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import PopupConfirm from "../Layout/PopupConfirm";
import PopupLayer from "../Layout/PopupLayer";
import Pagination from "../../components/Pagination";
import Excel from "../../components/Excel";
export default {
	name: 'OrderResult'
	, components: {Excel, Pagination, PopupLayer, PopupConfirm, Modal}
	, props: ['Axios', 'codes', 'TOKEN', 'seller_info', 'user']
	,data: function(){
		return {
			program: {
				name: '주문상세'
				,top: false
				,title: true
				,bottom: true
			}
			,item: {
				result: {

				}
				, supply_list: [

				]
				, order_number: this.order_num_new
			}
			,is_modal: false
			,modal_option: {
				top: true
				,title: '주문취소'
				,content: '해당 주문상품을 취소하시겠습니까?'
				,bottom: true
			}
			,odt_step_cancel: 'step21'
			,item_cancel: ''
			, is_on_purchase: false
			, is_purchase_request: false
			, is_on_receipt: false
			, item_search: {
				page: 1
				, list_cnt: 10
				, order_number: this.$route.params.order_num_new
			}
			, items_pin: []
			, excel_data: {
				name: ' PIN 목록'
				,header: [
					{ key: 0, name: '관리번호', column: 'uid'}
					,{ key: 0, name: 'PIN', column: 'pin'}
					,{ key: 0, name: '상태', column: 'status_name'}
				]
				,content: null
			}
			, is_excel: false
			, item_mobile: {}
			, is_on_detail: false
			, item_detail: {}
			, item_odt: {}
			, made_code: 'G000000027'
			, is_on_mileage: false
			, item_mileage: {}
		}
	}
	,computed: {
		pay_div: function(){
			let div = ''
			if(this.item.pay_div == 'bank'){
				div = '무통장 입금'
			}else if(this.item.pay_div == 'card'){
				div = '카드 결제'
			}
			return div
		}
		, is_purchase: function(){
			let t = false
			if(this.seller_info.sales_type == 1 && (!this.item.is_purchase || this.item.is_purchase == '0') && this.is_purchase_request){
				t = true
			}
			return t
		}
		,order_status: function(){
			let status = ''
			if(this.item.o_status == 1){
				status = '입금대기'
			}else{
				status = '결제완료'
			}
			return status
		}
		,item_list: function(){

			let self = this
			self.is_purchase_request = false
			return this.item.supply_list.filter(function(item){

				return item.odt_list.filter(function(odt){

					odt.options = {}
					if(odt.pdt_img1){
						odt.pdt_img = self.codes.img_url + odt.pdt_img1
					}
					switch(odt.order_status){
						default: case 'step1':
							odt.is_shipping = false
							odt.is_cancel = true
							self.is_purchase_request = true
							break
						case 'step2':
							odt.is_cancel = false
							odt.is_shipping = false

							item.is_cancel = false
							break
						case 'step3':

							item.is_cancel = false
							break
						case 'step4':
							odt.is_cancel = false
							odt.is_confirm = true
							odt.is_return = true

							item.is_cancel = false
							break
						case 'step5':

							item.is_cancel = false
							break
						case 'step21':
							odt.is_shipping = false

							item.is_step21 = false
							break
						case 'step22':
							odt.is_shipping = false

							//item.is_cancel = false
							break
						case 'step31':

							item.is_cancel = false
							break
						case 'step32':

							item.is_cancel = false
							break
						case 'step33':

							item.is_cancel = false
							break
						case 'step41':

							item.is_cancel = false
							break
						case 'step42':

							item.is_cancel = false
							break
						case 'step43':

							item.is_cancel = false
							break
					}

					return odt
				})
			})
		}
		, installment: function(){
			let t = ''
			switch (this.item.installment){
				case '00':
					t = '일시불'
					break;
				default:
					(Number(this.item.installment) * 1) + '개월'
					break;
			}
			return t
		}
		, is_m: function(){
			let t = false
			this.item.supply_list.filter(function(item){
				item.odt_list.filter(function(odt){
					if(odt.pdt_div == 'cucudas'){
						t = true
						return
					}
				})
			})
			return t
		}
		, pin_list: function(){
			let t = ''
			this.item.order_return.filter ( (item) => {
				t += (item.orgTranSeq ? '취소' : item.appNo) + ' ' + (item.orgTranSeq ? '' : item.issCd) + '\r\n'
			})
			return t
		}
		, is_made_coupon: function(){
			let t = false
			this.item.supply_list.filter((item) => {
				item.odt_list.filter((odt) => {
					if(odt.pdt_code.indexOf(this.made_code) > -1){
						t = true
						return
					}
				})
			})
			return t
		}
		, is_mileage: function(){
			let t = false
			this.items_pin.filter ( (item) => {
				if(!item.pgTidNm){
					t = true
				}
			})
			return t
		}
	}
	,methods:{
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.Axios({
					method: 'get'
					,url: 'order/getOrderDetail'
					,data: {
						order_num_new: this.$route.params.order_num_new
					}
				})

				if(result.success){
					this.item = result.data
					await this.getPin()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,toShipping: function(odt){
			let code = this.codes.G000.items
			let url = ''
			for(let i = 0; i < code.length; i++){
				if(code[i].total_code == odt.shipping_name){
					url = code[i].code_value + odt.shipping_num
					break
				}
			}
			console.log(url)
			window.open(url, 'shipping')
		}
		,toOdtStatus: async function(){
			try{
				const result = await this.Axios({
					method: 'post'
					, url: 'order/postOdtUpdate'
					, data: {
						uid: this.item_cancel.uid
						, next_step: this.odt_step_cancel
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.clearItem()
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}
		}
		,isOdtUpdate: function(odt, step){
			this.is_modal = true
			this.modal_option.type = step
			this.item_cancel = odt
		}
		,toOdtConfirm: function(odt){
			this.$router.push({ name: 'OdtConfirm', params: { odt_uid: odt.uid }})
		}
		,toOdtReturn: function(odt){
			this.$router.push({ name: 'OdtReturn', params: { odt_uid: odt.uid }})
		}
		,clearItem: function(){
			this.is_modal = false
			this.item = {
				supply_list: []
			}
			this.item_cancel = {}
			this.item_return = {}
			this.item_confirm = {}
			this.item_result = {}
			this.is_on_purchase = false
			this.$bus.$emit('on', false)
		}
		, postPurchaseRequest: async function(){
			try{
				const result = await this.Axios({
					method: 'post'
					, url: 'order/postPurchaseRequest'
					, data: {
						order_number: this.item.order_num_new
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_on_purchase = false
			}
		}
		, onPurchase: function(){
			this.is_on_purchase = true
		}

		,clipBoard2: function (value){
			const t = document.createElement("textarea");
			document.body.appendChild(t);
			t.value = value;
			t.select();
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy2: function (){

			this.clipBoard2(this.pin_list);
			alert('핀번호가 복사되었습니다.');
		}

		,clipBoard: function (value){
			const t = document.createElement("textarea");
			document.body.appendChild(t);
			t.value = value;
			t.select();
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy: function (){

			this.clipBoard(this.item.order_number);
			alert('매입코드가 복사되었습니다.');
		}
		, onReceipt: function(){
			this.is_on_receipt = true
		}
		, getPin: async function(){
			if(this.item.o_status != 2){
				return false
			}
			this.$bus.$emit('on', true)
			try{
				const result = await this.Axios({
					method: 'get'
					,url: 'order/getOderPin'
					,data: this.item_search
					, version: 'v2'
				})

				if(result.success){
					this.items_pin = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			this.item_search.page = page
			this.getPin()
		}
		,toExcel: function(){
			this.excel_data.name = this.item.order_number + ' PIN 목록'
			this.excel_data.content = this.items_pin
			this.is_excel = true
		}

		, getDetail: function (item) {
			this.getDetailGiftcon(item)
		}

		, getDetailMobile: async function(item){
			try {
				const result = await this.Axios({
					method: 'get'
					, url: 'order/getOrderMobileDetail'
					, data: {
						tid: item.tranSeq
						, service_code: item.cardNum
						, orderid: this.item.order_number
					}
					, version: 'v2'
				})

				if (result.success) {
					this.item_mobile = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.item_detail = item
				this.is_on_detail = true
			}
		}
		, getDetailGiftcon: async function(item){
			try {
				const result = await this.Axios({
					method: 'get'
					, url: 'order/getOrderGiftconDetail'
					, data: {
						tid: item.tranSeq
						, orderid: this.item.order_number
					}
					, version: 'v2'
				})

				if (result.success) {
					this.item_mobile = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.item_detail = item
				this.is_on_detail = true
			}
		}

		, postCancel: function(){
			this.postGiftconCancel()
		}
		, postMobileCancel: async function () {
			if (!confirm("해당 상품권을 사용 취소 요청하시겠습니까?")) {
				return false
			}
			try {
				this.$bus.$emit('on', true)
				const result = await this.Axios({
					method: 'get'
					, url: 'order/postMobileCancel'
					, data: {
						tid: this.item_detail.tranSeq
						, service_code: this.item_detail.cardNum
						, orderid: this.item.order_number
					}
					, version: 'v2'
				})

				if (result.success) {
					this.$set(this.item_detail, 'orgTranSeq', result.data.TID)
					this.$set(this.item_detail, 'appTm', result.data.CANCELTIME)
					this.is_on_detail = false
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, postGiftconCancel: async function () {
			if (!confirm("해당 기프티콘을 사용 취소 요청하시겠습니까?")) {
				return false
			}
			try {
				this.$bus.$emit('on', true)
				const result = await this.Axios({
					method: 'get'
					, url: 'order/postGiftconCancel'
					, data: {
						tid: this.item_detail.tranSeq
						, orderid: this.item.order_number
					}
					, version: 'v2'
				})

				if (result.success) {
					await this.getData()
					this.is_on_detail = false
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, postTry: async function(){
			if(!confirm("해당 기프티콘을 재발행 요청하시겠습니까?")){
				return false
			}
			try{
				this.$bus.$emit('on', true)
				const result = await this.Axios({
					method: 'post'
					,url: 'order/postGiftconTry'
					,data: {
						uid: this.item_detail.uid
					}
					, version: 'v2'
				})

				if(result.success){
					await this.getData()
					this.is_on_detail = false
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMileage: function(){
			this.is_on_mileage = true
		}
		, postMileage: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.Axios({
					method: 'post'
					,url: 'order/postMileage'
					,data: {
						order_number: this.$route.params.order_num_new
						, member_id: this.item_mileage.member_id
						, member_pw: this.item_mileage.member_pw
					}
					, version: 'v2'
				})

				if(result.success){
					this.is_on_mileage = false
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
		if(this.user.member_memo){
			let t = JSON.parse(this.user.member_memo)
			this.item_mileage.member_id = t.member_id
			this.item_mileage.member_pw = t.member_pw
		}
	}
}
</script>

<style>
.pdt-img { width: 80px; height: auto; overflow: hidden}
</style>